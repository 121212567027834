/* eslint-disable no-script-url,jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { useLocation } from "react-router";
import { NavLink } from "react-router-dom";
import { menuLabels, menuSubLabels, settingsLabels } from "../../../../misc";
import { checkIsActive } from "../../../../_helpers";
import NatekLogo from "../../../../_images/NATEK-logo.png";
import SettingsIcon from "@material-ui/icons/Settings";
import { Button } from "@material-ui/core";
import ConfirmPopover from "../../common/ConfirmPopover";
import { useSelector } from "react-redux";
import CustomSnackbar from "../../../../../app/modules/UserProfile/components/CustomSnackbar";
import DeleteCandidateProfile from "../../../../../app/modules/UserProfile/components/DeleteCandidateProfilePopover.jsx";

export const HeaderMenu = ({ layoutProps }) => {
  const location = useLocation();
  const [deleteProfilePopover, setDeleteProfilePopover] = useState(false);
  const [deleteEmailSent, setDeleteEmailSent] = useState(false);
  const [deleteMessageInfo, setDeleteMessageInfo] = useState(null);
  const { access_token } = useSelector(state => state.auth);

  const handleDeleteEmailSentClose = () => setDeleteEmailSent(false);

  const sendDeleteMailApiCall = async (url) => {
    let message = {
      text: "Delete confirmation email successfully sent!",
      severenity: "success"
    }
    try {
      const apiResponse = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`
        },
      });
  
      const apiResponseJSON = await apiResponse.json();
  
      const payload = apiResponseJSON.data ? apiResponseJSON.data[Object.keys(apiResponseJSON.data)[0]] : apiResponseJSON.message;
      if (!apiResponse.ok) {
        message.text = payload;
        message.severenity = "error"
      } else {
        message.text = apiResponseJSON.message;
      }
      setDeleteMessageInfo(message);
    } catch (error) {
      message.text = error.message;
      message.severenity = "error";
      setDeleteMessageInfo(message);
    }
    setDeleteEmailSent(true);
  };

  const getMenuItemActive = url => {
    return checkIsActive(location, url) ? "menu-item-active" : "";
  };


  const candidate = useSelector(state => state.auth.user);
  let isDeleteProfileClickable = false;

  const renderMenuSubListItems = items => {

    // Check If candidate is or was employed. Depending on that allow profile deletion.
    if (candidate.previous_employment === false && candidate.employeeType === "Not Employed" && candidate.employed === false) {
      isDeleteProfileClickable = true;
    }

    return items.map(listItem => (
      <li
        className={`menu-item menu-item-submenu ${getMenuItemActive(listItem.className)}`}
        data-menu-toggle="hover"
        aria-haspopup="true"
        key={listItem.label}
      >
        {listItem.redirect ? (
          <NavLink className="menu-link" to={listItem.redirect}>
            <span className="menu-text">{listItem.label}</span>
          </NavLink>
        ) : (
            <Button className="menu-link"
            style={{
                  display: 'inline-block',
                  backgroundColor: isDeleteProfileClickable ? '' : 'rgb(243,243,243)',
                }}
                onClick={() => isDeleteProfileClickable && setDeleteProfilePopover(true)}
            >
              {!isDeleteProfileClickable ? (
                    <DeleteCandidateProfile />
              ) : (
                  <span className="menu-text">{listItem.label}</span>
              )}
            </Button>
        )}
      </li>
    ));
  };

  const renderMenuListItem = () =>
    menuLabels.map(listItem =>
      listItem.label.toLowerCase() === "offer letter" ? (
        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive(listItem.className)}`}
          key={listItem.label}
        >
          <NavLink className="menu-link menu-toggle" to="/">
            <span className="menu-text">{listItem.label}</span>
          </NavLink>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left">
            <ul className="menu-subnav">{renderMenuSubListItems(menuSubLabels)}</ul>
          </div>
        </li>
      ) : (
        <li className={`menu-item menu-item-rel ${getMenuItemActive(listItem.className)}`} key={listItem.label}>
          <NavLink className="menu-link" to={listItem.redirect}>
            <span className="menu-text">{listItem.label}</span>
            {layoutProps.rootArrowEnabled && <i className="menu-arrow" />}
          </NavLink>
        </li>
      )
    );
  
  const handleClosePopover = () => setDeleteProfilePopover(false);

  const handleConfirm = () => {
    //send delete email
    sendDeleteMailApiCall(`${process.env.REACT_APP_API_URL}/account/delete`);
    setDeleteProfilePopover(false);
  }

  return (
    <div id="kt_header_menu" className={`header-menu header-menu-mobile ${layoutProps.ktMenuClasses}`} {...layoutProps.headerMenuAttributes}>
      {deleteEmailSent && (
        <CustomSnackbar
          handleClose={handleDeleteEmailSentClose}
          severenity={deleteMessageInfo && deleteMessageInfo.severenity}
          autoHideDuration={3500}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={deleteEmailSent}
        >
          {deleteMessageInfo && deleteMessageInfo.text}
        </CustomSnackbar>
      )}
      {deleteProfilePopover && (
        <ConfirmPopover 
          isOpened={deleteProfilePopover} 
          handleClosePopover={handleClosePopover}
          handleConfirm={handleConfirm}
          title={"Are you sure you want to delete your profile?"}
        />
      )}
      <ul className={`menu-nav ${layoutProps.ulClasses}`}>
        <NavLink to="/">
          <img src={NatekLogo} alt="Natek Logo" className="metronic_natek-logo" />
        </NavLink>

        {renderMenuListItem()}

        <li
          data-menu-toggle={layoutProps.menuDesktopToggle}
          aria-haspopup="true"
          className={`menu-item menu-item-submenu menu-item-rel ${getMenuItemActive("/settings")} metronic_last-item`}
        >
          <span className="svg-icon svg-icon-xl svg-icon-primary menu-link menu-toggle metronic_settings-icon">
            <SettingsIcon />
          </span>
          <div className="menu-submenu menu-submenu-classic menu-submenu-left" style={{marginTop : "-10px"}}>
            <ul className="menu-subnav">{renderMenuSubListItems(settingsLabels)}</ul>
          </div>
        </li>
      </ul>
    </div>
  );
};
