import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { FormattedMessage, injectIntl } from "react-intl";
import CustomButton from "../../../../_metronic/layout/components/common/CustomButton";
import { loginUser, removeEmailVerifiedMessage, removeFailedNotification, setRedirectPath, setTokenValidationError, verifyUser } from "../../../../redux/modules/auth/authActions";
import { timeToClose } from "../../../../_metronic/misc";
import { useParams } from "react-router";
import CustomSnackbar from "../../UserProfile/components/CustomSnackbar";
import { setJobLastId } from "../../../../redux/modules/data/dataActions";

const initialValues = {
  email: "",
  password: ""
};


const Login = ({ intl }) => {
  const [loading, setLoading] = useState(false);
  const [verificationMessageOpen, setVerificationMessageOpen] = useState(true);
  const dispatch = useDispatch();
  const { hasErrorMessage, emailVerified, tokenValidationError } = useSelector(state => state.auth);
  const { jobLastId } = useSelector(state => state.data);
  const { id, hash, expiration, signature } = useParams();
  const location = useLocation();

  useEffect(()=>{
    if(id) {
      const endpoint = `email/verify/${id}/${hash}?expires=${expiration}&signature=${signature}`;
      dispatch(verifyUser({ endpoint }));
      const searchParams = location.search;
      if (searchParams.includes("action") && searchParams.includes("entity_id")) {
        const action = searchParams.split('&')[0].split("=")[1];
        const jobId = searchParams.split('&')[1].split("=")[1];
        if(action === "job-apply") {
          const path = `/jobs/${jobId}/apply`;
          dispatch(setRedirectPath(path));
          dispatch(setJobLastId(jobLastId));
        }
      } else if (jobLastId) {
          const path = `/jobs/${jobLastId}/apply`;
          dispatch(setRedirectPath(path));
          dispatch(setJobLastId(jobLastId));
      }
      setTimeout(() => {
        window.location.href = "/auth/login"
      }, 4000);
    }
    // eslint-disable-next-line
  }, [id, expiration, hash, signature, dispatch])

  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email("Wrong email format")
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      ),
    password: Yup.string()
      .min(3, "Minimum 3 symbols")
      .max(50, "Maximum 50 symbols")
      .required(
        intl.formatMessage({
          id: "AUTH.VALIDATION.REQUIRED_FIELD"
        })
      )
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues,
    validationSchema: LoginSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      setSubmitting(true);
      setLoading(true);

      const data = {
        grant_type: "password",
        client_id: process.env.REACT_APP_CLIENT_ID,
        client_secret: process.env.REACT_APP_CLIENT_SECRET_ID,
        username: values.email,
        email: values.email,
        password: values.password,
        scope: "*"
      };

      dispatch(loginUser(data));
    }
  });

  const handleClose = () => dispatch(removeFailedNotification());

  useEffect(() => {
    let isCancelled = false;

    if (!isCancelled) {
      if (hasErrorMessage) {
        setLoading(true);

        setTimeout(() => {
          handleClose();
          setLoading(false);
        }, timeToClose);
      } else {
        setLoading(false);
      }
    }

    return () => (isCancelled = true);
    // eslint-disable-next-line
  }, [hasErrorMessage]);

  useEffect(() => {
    if (emailVerified) {
      setTimeout(() => {
        setVerificationMessageOpen(false);
        dispatch(removeEmailVerifiedMessage());
      }, timeToClose);
    }
  }, [emailVerified, dispatch]);

  const handleEmailVerifiedClose = () => {
    setVerificationMessageOpen(false);
    dispatch(removeEmailVerifiedMessage());
  }

  return (
    <>
      {emailVerified && (
        <CustomSnackbar
          handleClose={() => handleEmailVerifiedClose()}
          severenity={"success"}
          autoHideDuration={3500}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={verificationMessageOpen}
        >
          Email confirmed!
        </CustomSnackbar>
      )}
      {tokenValidationError && (
        <CustomSnackbar
          handleClose={() => dispatch(setTokenValidationError(null))}
          severenity={"error"}
          autoHideDuration={3500}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={tokenValidationError}
        >
          {tokenValidationError}
        </CustomSnackbar>
      )}
      <div className="login-form login-signin" id="kt_login_signin_form">
        {/* begin::Head */}
        <div className="text-center mb-10 mb-lg-20">
          <h3 className="font-size-h1">
            <FormattedMessage id="AUTH.LOGIN.TITLE" />
          </h3>
          <p className="text-muted font-weight-bold">Enter your username and password</p>
        </div>
        {/* end::Head */}

        {hasErrorMessage && (
          <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
            <div className="alert-text font-weight-bold">{hasErrorMessage}</div>
          </div>
        )}

        {/*begin::Form*/}
        <form 
          onSubmit={formik.handleSubmit} 
          className="form fv-plugins-bootstrap fv-plugins-framework"
        >
          {formik.status && (
            <div className="mb-10 alert alert-custom alert-light-danger alert-dismissible">
              <div className="alert-text font-weight-bold">{formik.status}</div>
            </div>
          )}

          {/*begin:: Email field */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Email"
              type="email"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("email")}`}
              name="email"
              {...formik.getFieldProps("email")}
            />
            {formik.touched.email && formik.errors.email ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.email}</div>
              </div>
            ) : null}
          </div>
          {/*end:: Email field */}

          {/*begin:: Password field */}
          <div className="form-group fv-plugins-icon-container">
            <input
              placeholder="Password"
              type="password"
              className={`form-control form-control-solid h-auto py-5 px-6 ${getInputClasses("password")}`}
              name="password"
              {...formik.getFieldProps("password")}
            />
            {formik.touched.password && formik.errors.password ? (
              <div className="fv-plugins-message-container">
                <div className="fv-help-block">{formik.errors.password}</div>
              </div>
            ) : null}
          </div>
          {/*end:: Password field */}

          {/*begin:: Sign in button */}
          <div className="form-group d-flex flex-wrap justify-content-between align-items-center">
            <Link to="/auth/forgotten-password/request" className="text-dark-50 text-hover-primary my-3 mr-2" id="kt_login_forgot">
              <FormattedMessage id="AUTH.GENERAL.FORGOT_BUTTON" />
            </Link>
            <CustomButton
              color="primary"
              variant="contained"
              id="kt_login_signin_submit"
              type="submit"
              disabled={loading}
              className={`btn btn-light-primary font-weight-bold px-9 py-4 my-3`}
            >
              <span className={loading ? "mr-2" : ""}>Sign In</span>
              {loading && <span className="mr-4 spinner spinner-white"></span>}
            </CustomButton>
          </div>
        </form>
        {/*end:: Sign in button */}

        {/*end::Form*/}
      </div>
    </>
  );
};

export default injectIntl(Login);
