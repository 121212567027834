import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ModalProgressBar } from "../../../_metronic/_partials/controls";
import CustomButton from "../../../_metronic/layout/components/common/CustomButton";
import { freshUserData } from "../../../redux/modules/auth/authActions";
import InfoSection from "./components/InfoSection";
import InfoField from "./components/InfoField";
import SelectField from "./components/SelectField";
import FileField from "./components/FileField";
import { fulfilledProfileSchema } from "../../../redux/modules/data/dataActions";
import ImageField from "./components/ImageField";
import CustomSnackbar from "./components/CustomSnackbar";
import { timeToClose } from "../../../_metronic/misc";
import ConfirmPopover from "../../../_metronic/layout/components/common/ConfirmPopover";
import { injectIntl } from "react-intl";
import { emailRegex, linkedinRegex, linkedinValidationMessage, validateFile } from "../../helpers";
import CandidateProfilePopover from "./components/PopoverCandidateProfiles";
const PersonaInformation = ({
  intl
}) => {
  const [loading, setloading] = useState(false);
  const dispatch = useDispatch();
  const { access_token } = useSelector(state => state.auth);
  const user = useSelector(state => state.auth.user);
  const { profileSchema } = useSelector(state => state.data);
  const [files, setFiles] = useState({});
  const [fileIsRequired, setFileIsRequired] = useState(false);
  const [selectFields, setSelectFields] = useState({});
  const [selectValueIsRequired, setSelectValueIsRequired] = useState(false);
  const [savedProfileNotification, setSavedProfileNotification] = useState(null);
  const [savedProfileNotificationType, setSavedProfileNotificationType] = useState("success");
  const [deleteFilePopover, setDeleteFilePopover] = useState(false);
  const [deleteFileInfo, setDeleteFileInfo] = useState(null);
  const [fileError, setFileError] = useState("");
  const [imageError, setImageError] = useState("");

  const initialFields = profileSchema && profileSchema.map(field => field.fields).flat(1);

  const checkForRequiredFiles = (files) => {
    //filter fileFields by required and no value in files! ... execute on each upload or delete
    const fileFields = initialFields.filter((field)=>field.type==="file");
    const failedValidationFileFields = fileFields.filter(field=>field.required && !files[field.name]);
    setFileIsRequired(failedValidationFileFields.length > 0);
  }

  const checkForRequiredSelectFields = (fields) => {
    //filter fileFields by required and no value in files! ... execute on each upload or delete
    const filteredSelectFields = initialFields.filter((field)=>field.type==="select");
    const failedValidationSelectFields = filteredSelectFields.filter(field=>{
      if (field.required && field.parameters && field.parameters.multiple && fields[field.name].length === 0) {
        return true;
      }
      if (field.required && !fields[field.name]) {
        return true
      }
      return false;
    });
    setSelectValueIsRequired(failedValidationSelectFields.length > 0);
  }

  const setInitialFiles = ()=>{
    const fileFields = initialFields.filter((field)=>field.type==="file");
    const initialFiles = fileFields
      .reduce((arr, field) => ({ ...arr, [field.name]: field.value}), {});
    setFiles(initialFiles);
    checkForRequiredFiles(initialFiles);
  };

  const setInitialSelectFields = ()=>{
    const selectFields = initialFields.filter((field)=>field.type==="select");
    const initialSelectFields = selectFields
      .reduce((arr, field) => ({ ...arr, [field.name]: field.value}), {});
    setSelectFields(initialSelectFields);
    checkForRequiredSelectFields(initialSelectFields);
  };

  useEffect(()=>{
    //separate file and select fields from Formik, so it can allow multiple select and delete functionality
    setInitialFiles();
    setInitialSelectFields();
    // eslint-disable-next-line
  }, [profileSchema]);

  const handleClosePopover = () => {
    setDeleteFilePopover(false);
    setDeleteFileInfo(null);
  };

  const confirmDelete = () => {
      setDeleteFilePopover(false);
      handleFileDelete();
  }

  const onDelete = (i, multiple, fieldId) => {
    setDeleteFilePopover(true);
    setDeleteFileInfo({
      i,
      multiple,
      fieldId
    });
  }

  const handleClose = () => setSavedProfileNotification(null);

  const postApiCall = async (url, requestBody) => {
    try {
      const apiResponse = await fetch(url, {
        method: "POST",
        headers: {
          Authorization: `Bearer ${access_token}`
        },
        body: requestBody
      });

      const apiResponseJSON = await apiResponse.json();

      if (!apiResponse.ok) {
        const payload = apiResponseJSON.data ? apiResponseJSON.data[Object.keys(apiResponseJSON.data)[0]] : apiResponseJSON.message;
        setSavedProfileNotification(payload);
        setSavedProfileNotificationType("error");
        throw new Error(payload);
      } else {
        const data = apiResponseJSON;
        dispatch(fulfilledProfileSchema(data));
        setSavedProfileNotification("Your changes were successfully submitted!");
        setSavedProfileNotificationType("success");
      }
    } catch (error) {
      console.log(error, "error");
    }
  };

  const deleteApiCall = async (url) => {
    try {
      const apiResponse = await fetch(url, {
        method: "DELETE",
        headers: {
          Authorization: `Bearer ${access_token}`
        }
      });

      const apiResponseJSON = await apiResponse.json();

      if (!apiResponse.ok) {
        const payload = apiResponseJSON.data ? apiResponseJSON.data[Object.keys(apiResponseJSON.data)[0]] : apiResponseJSON.message;
        throw new Error(payload);
      }
    } catch (error) {
      console.log(error, "error");
    }
  };


  const appendValueToFormData = (formData, value, formattedKey) => {
    //handle files uploaded previously
    const isFile = value instanceof File;
    if (typeof value !== "object" || (typeof value === "object" && isFile)) {
      formData.append(formattedKey, value);
    }
  }

  const saveUser = (values, setStatus, setSubmitting) => {
    setloading(true);

    //before setting the form data we merge the manually handled file and select fields to the formik values
    const updatedValues = {...values};
    Object.entries(selectFields).forEach(([name, value])=>{
      updatedValues[name] = value;
    });
    Object.entries(files).forEach(([name, value])=>{
      updatedValues[name] = value;
    });

    const formDataPR = new FormData();
    //append values to form data depending on value type
    Object.keys(updatedValues).forEach(key => {
      const formattedKey = isNaN(key) ? key : `_${key}`;
      
      if (updatedValues[key]) {
        //handle arrays
        if(Array.isArray(updatedValues[key])) {
          for (let i = 0; i < updatedValues[key].length; i++) {
            appendValueToFormData(formDataPR, updatedValues[key][i], `${formattedKey}[]`)
          }
        } else {
          appendValueToFormData(formDataPR, updatedValues[key], formattedKey)
        }
      } else {

        formDataPR.append(formattedKey, "");
      }
    })

    postApiCall(`${process.env.REACT_APP_API_URL}/profile`, formDataPR);

    setTimeout(() => {
      dispatch(freshUserData(access_token));
      setloading(false);
      setSubmitting(false);
    }, 1000);
  };

  const getFieldValueValidation = (field) => {
    if (field.name === "linkedin_url") {
      if (field.required) {
        return Yup.string()
        .required(`${field.label} is required`)
        .matches(linkedinRegex, linkedinValidationMessage)
      }
      return Yup.string()
      .matches(linkedinRegex, linkedinValidationMessage)
      .nullable()
    }
    if (field.name === "facebook_url") {
      const facebookRegex = /^(http(s)?:\/\/)?([w]+.)?facebook.com\/[a-zA-Z-._0-9]+(\/)?$/i;
      const facebookValidationMessage = `Please, enter a valid ${field.label} URL`;
      if (field.required) {
        return Yup.string()
        .required(`${field.label} is required`)
        .matches(facebookRegex, facebookValidationMessage)
      }
      return Yup.string()
      .matches(facebookRegex, facebookValidationMessage)
      .nullable()
    }
    if (field.name === "skype") {
      const skypeRegex = /^[a-zA-Z0-9.,\-_@ ]+$/i;
      const skypeValidationMessage = "Please, enter a valid Skype name";
      return Yup.string()
      .matches(skypeRegex, skypeValidationMessage)
      .nullable()
    }
    if (field.name === "email") {
      return Yup.string()
        //.email("Wrong email format")
        .matches(emailRegex, "Wrong email format")
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          })
        )
    }
    if (field.name === "phone") {
      const phoneRegExp = /^(\+|00)[1-9 ]{1}[0-9 ]{3,14}$/
      if (field.required) {
        return Yup.string()
          .required(`${field.label} is required`)
          .matches(phoneRegExp, "Please, enter a valid phone with country code")
      }
      return Yup.string()
        .matches(phoneRegExp, "Please, enter a valid phone with country code").nullable()
    }
    if (field.name === "first_name" || field.name === "family_name") {
      return Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .matches(/^[a-zA-Z-\s]+$/, intl.formatMessage({ id: "AUTH.VALIDATION.SPECIAL_CHARS" }))
        .required(
          intl.formatMessage({
            id: "AUTH.VALIDATION.REQUIRED_FIELD"
          })
        )
      }
      if (field.label === "Middle Name") {
        return Yup.string()
        .min(3, "Minimum 3 symbols")
        .max(50, "Maximum 50 symbols")
        .matches(/^[a-zA-Z-\s]+$/, intl.formatMessage({ id: "AUTH.VALIDATION.SPECIAL_CHARS" }))
        .nullable()
    }
    if(field.required && field.type !== "file" && field.type !== "select") {
      return Yup.string().required(`${field.label} is required`).nullable();
    }
    if(field.type === "string") {
      return Yup.string().nullable();
    }
    return Yup.mixed()
  }
 
  const initialReduceValues = initialFields
    .reduce((a, field) => ({ ...a, [field.name]: (field.type === "string" || field.type === "url") && !field.value ? "" : field.value}), {});
  
  const ReduceSchemaValues = initialFields
    .reduce((arr, field) => {
      const val = ({ ...arr, [field.name]: getFieldValueValidation(field)});
      return val;
    }, {});

  const ReducedSchema = Yup.object().shape({
    ...ReduceSchemaValues
  });

  const getInputClasses = fieldname => {
    if (formik.touched[fieldname] && formik.errors[fieldname]) {
      return "is-invalid";
    }

    if (formik.touched[fieldname] && !formik.errors[fieldname]) {
      return "is-valid";
    }

    return "";
  };

  const formik = useFormik({
    initialValues: initialReduceValues,
    validationSchema: ReducedSchema,
    onSubmit: (values, { setStatus, setSubmitting }) => {
      saveUser(values, setStatus, setSubmitting);
    },
    onReset: (values, { resetForm }) => {
      resetForm();
    }
  });

  const handleFileDelete = () => {
    const { i, multiple, fieldId } = deleteFileInfo;
    //remove option from field value
    const updatedFiles = {...files};
    if (multiple) {
      const currentFiles = updatedFiles[fieldId] ? updatedFiles[fieldId] : [];
      currentFiles.splice(i, 1);
      updatedFiles[fieldId] = currentFiles;
    } else {
      updatedFiles[fieldId] = null;
    }
    const fileValue = files[fieldId];
    const fileId = fileValue && fileValue.id;
    if (fileId) deleteApiCall(`${process.env.REACT_APP_API_URL}/profile/file/${fileId}`);
    setFiles(updatedFiles);
    checkForRequiredFiles(updatedFiles);
    setDeleteFileInfo(null);
  }

  const handleUploadFile = (e, id, multiple) => {
    const newFile = e.target.files[0];
    if (!newFile) return;
    const {isFileValid, fileErr} = validateFile(newFile, id);

    if(isFileValid) {
      const updatedFiles = {...files};
      if (multiple) {
        const currentFiles = updatedFiles[id] ? updatedFiles[id] : [];
        currentFiles.push(newFile);
        updatedFiles[id] = currentFiles;
      } else {
        updatedFiles[id] = newFile;
      }
      setFiles(updatedFiles);
      checkForRequiredFiles(updatedFiles);
      if (id === "profile_photo") {
        setImageError("");
      } else {
        setFileError("");
      }
    } else {
      if (id === "profile_photo") {
        setImageError(fileErr);
      } else {
        setFileError(fileErr);
      }
    }
  };

  const submitDisabled = () => {
    return (fileIsRequired || selectValueIsRequired || (formik.touched && !formik.isValid));
  }

  return (
    <section className="d-flex justify-content-center">
      {deleteFilePopover && (
        <ConfirmPopover 
          isOpened={deleteFilePopover} 
          handleClosePopover={handleClosePopover}
          handleConfirm={confirmDelete}
          title={"Are you sure you want to delete this file?"}
        />
      )}
      {savedProfileNotification && (
        <CustomSnackbar
          handleClose={handleClose}
          severenity={savedProfileNotificationType}
          autoHideDuration={timeToClose}
          anchorOrigin={{ horizontal: "right", vertical: "top" }}
          open={true}
        >
          {savedProfileNotification}
        </CustomSnackbar>
      )}
      <form className="card card-custom card-stretch m-3 metronic_my-profile-container" onSubmit={formik.handleSubmit}>
        {loading && <ModalProgressBar />}

        {/* begin::Header */}
        <div className="card-header py-3">
          <div className={user.employed || user.previous_employment ? "card-title align-items-start flex-row mt-5" : "card-title align-items-start flex-column"}>
            <h3 className="card-label font-weight-bolder text-dark">Personal Information</h3>
            {(user.employed || user.previous_employment) && (
                <span aria-label="info" style={{ marginLeft: '-5px', marginTop: '-3px' }}>
                  <CandidateProfilePopover />
                </span>
            )}
            {!user.employed && !user.previous_employment && (
                <span className="text-muted font-weight-bold font-size-sm mt-1">Update your personal information</span>
            )}
          </div>
          <div className="card-toolbar">
            <CustomButton>
              <Link to="/">Back</Link>
            </CustomButton>
          </div>
        </div>
        {submitDisabled() && !user.employed && (
          <div className="global-warning display-end">
            Please fill and verify all required fields!
          </div>
        )}
        {/* end::Header */}

        {/* begin::Form */}
        <div className="form">
          {/* begin::Body */}
          <div className="card-body">
            {profileSchema && profileSchema.map((section, index) => {
              if (section.id === 0){
                section.fields[0].label = "Profile Picture"
                section.fields.splice(1,1)
              }
              const { fields } = section;
              //render each section and its fields
              return (
                <React.Fragment key={index}>
                  <InfoSection key={index} name={section.name} />
                 {fields.map((field, i) => {
                   if((field.type==="file" && field.parameters.avatar) || (field.type==="file" && field.parameters.type==="image")) {
                     return (
                        <ImageField
                          key={i}
                          field={field}
                          handleUploadFile={handleUploadFile}
                          files={files}
                          handleDelete={onDelete}
                          error={imageError}
                          readonly={user.employed}
                        />
                     )
                   }

                   //If user has a previous employment, first name, middle name and surname are readonly
                   if (user.previous_employment) {
                     if (field.name === "first_name" || field.name === "129"  || field.name === "family_name" ) {
                       field.readonly = true;
                     }
                   }

                   if(field.type==="file") {
                     return (
                       <FileField
                        key={i}
                        field={field}
                        value={files[field.name]}
                        handleUploadFile={handleUploadFile}
                        handleDelete={onDelete}
                        error={fileError}
                        readonly={user.employed}
                      />
                     )
                   }

                   if(field.type === 'select') {
                    return (
                      <SelectField
                        key={i}
                        field={field}
                        fieldId={field.name}
                        value={selectFields[field.name]}
                        selectFields={selectFields}
                        setSelectFields={setSelectFields}
                        checkForRequiredSelectFields={checkForRequiredSelectFields}
                        readonly={user.employed}
                      />
                    )
                  }

                   return (
                    <InfoField 
                      key={i}
                      formik={formik} 
                      getInputClasses={getInputClasses}
                      type={field.type}
                      label={field.label}
                      placeholder={field.placeholder}
                      fieldId={field.name}
                      icon={field.icon}
                      required={field.required}
                      readonly={field.readonly}
                    />
                   )
                 })}
                </React.Fragment>
              ) 
            })}
          </div>
          {/* end::Body */}
        </div>
        {/* begin::Footer */}
        <div className="card-header py-3" style={{borderBottom: 'unset', borderTop: '1px solid rgb(235, 237, 243)'}}>
          <div className="card-title align-items-start flex-column invisible">
            <h3 className="card-label font-weight-bolder text-dark">Personal Information</h3>
            <span className="text-muted font-weight-bold font-size-sm mt-1">Update your personal informaiton</span>
          </div>
          <div className="card-toolbar">
            {!user.employed &&
            <CustomButton type="submit" className="mr-2" disabled={formik.isSubmitting || submitDisabled()}>
              <>
                Save Changes
                {formik.isSubmitting}
              </>
            </CustomButton>
            }
            <CustomButton>
              <Link to="/">Back</Link>
            </CustomButton>
          </div>
        {/* end::Footer */}
        </div>
        {/* end::Form */}
      </form>
    </section>
  );
};

export default injectIntl(PersonaInformation);
